import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { loginUser, registerUser } from "../../services/user.services"
import AuthUserContext from "../../providers/AuthProvider"
import ErrorsContext from "../../providers/ErrorsProvider"
import {
  DASHBOARD,
  LANDING,
  RESET_PASSWORD_START,
  HERBS,
  REGISTER,
  LOGIN,
  PRIVACY_POLICY,
} from "../Layout/routes"
import { PasswordResetContainer } from "../Layout/BareLayout"
import { Logo, DisclaimerMessage } from "../Layout/GlobalStyle"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import withLocation from "../common/withLocation"
import { navigate } from "@reach/router"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}))

function Register(props) {
  const [loading, setLoading] = useState(false)
  const { setErrors, errors, clearErrors } = useContext(ErrorsContext)
  const [email, setEmail] = useState("")
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [password, setPassword] = useState("")
  const [acceptedToTermsOfUse, setAcceptedTermsOfUse] = useState(false)
  const [message, setMessage] = useState("")
  const context = useContext(AuthUserContext)
  const classes = useStyles()

  function handleEmailChange(e) {
    setEmail(e.target.value)
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value)
  }

  const handleNavigation = async decoded => {
    let { management, search } = props
    let { redirect = false } = search

    let { role, client_information } = decoded
    if (redirect) {
      // Router.push(`${redirect}`)
      await navigate(redirect)
    } else {
      await navigate("/")
    }
  }

  const handleKeyDown = e => {
    let { keyCode } = e
    if (keyCode === 13) {
      handleSubmit()
    }
  }

  function handleSubmit() {
    setLoading(true)
    let { setUser, setReady } = context
    registerUser({ email, password, first_name, last_name })
      .then(decoded => {
        setLoading(false)
        setUser(decoded)
        setReady(true)
        handleNavigation(decoded)
      })
      .catch(err => {
        setLoading(false)
        if (err.response.data) {
          setErrors(err.response.data)
        }
      })
  }

  return (
    <>
      <PasswordResetContainer>
        <div className="wrapper">
          <div className="company-logo">
            <Link href={LANDING}>
              <a>
                <Logo />
              </a>
            </Link>
          </div>

          <div className="wrapper-content">
            <div style={{ textAlign: "left" }} className="column-title">
              Register
            </div>
            <p className="column-description">
              Join the world's best herb encyclopedia
            </p>

            <div className="input-wrapper" data-private>
              <TextField
                size="small"
                value={first_name}
                onChange={e => setFirstName(e.target.value)}
                helperText={errors.first_name ? errors.first_name : ""}
                error={errors.first_name ? true : false}
                id="firstNameFormInput"
                label="First name"
                variant="outlined"
              />
              <TextField
                size="small"
                value={last_name}
                onChange={e => setLastName(e.target.value)}
                helperText={errors.last_name ? errors.last_name : ""}
                error={errors.last_name ? true : false}
                id="lastNameFormInput"
                label="Last name"
                variant="outlined"
              />
              <TextField
                size="small"
                value={email}
                onChange={e => setEmail(e.target.value)}
                helperText={errors.email ? errors.email : ""}
                error={errors.email ? true : false}
                id="emailFormInput"
                label="Email"
                variant="outlined"
              />
              <TextField
                size="small"
                type="password"
                onKeyUp={e => handleKeyDown(e)}
                value={password}
                onChange={e => setPassword(e.target.value)}
                helperText={
                  errors.password
                    ? errors.password
                    : "Must contain at least 8 characters"
                }
                error={errors.password ? true : false}
                id="passwordFormInput"
                label="Password"
                variant="outlined"
              />
            </div>

            <Button
              disableElevation
              variant={"contained"}
              color="secondary"
              fullWidth
              onClick={() => handleSubmit()}
              disabled={loading}
            >
              Creat{loading ? "ing" : "e"} Account
            </Button>
            <p className="signup-link">
              Already have an account{" "}
              <Link to={LOGIN}>click here to login</Link>.
            </p>
          </div>
          <DisclaimerMessage color="#fff">
            By registering your account, you agree to our{" "}
            <Link to={PRIVACY_POLICY}> Terms of Service</Link> and{" "}
            <Link to={PRIVACY_POLICY}> Privacy Policy</Link>.
          </DisclaimerMessage>
          {message ? (
            <>
              <p className="query-message">{message}</p>
            </>
          ) : null}
        </div>
      </PasswordResetContainer>
    </>
  )
}

export default withLocation(Register)
