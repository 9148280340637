import React from 'react'
import styled from 'styled-components'
import { GlobalStyle, theme, BreakpointMobile, muiTheme } from '../GlobalStyle'
import { ErrorsProvider } from '../../../providers/ErrorsProvider'
import { AuthProvider } from '../../../providers/AuthProvider'
import PropTypes from "prop-types"
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import { useStaticQuery, graphql } from "gatsby"






const Container = styled.div`
    min-height: 100vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #353130;
  .company-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      font-family: var(--Font1);
      color: white;
      font-size: x-large;
      white-space: nowrap;
    }
  }
`


const Wrapper = styled.div`
    max-width: 350px;
`

const Content = styled.div`
    width: 100%;
    height: 100%;
`


export const PasswordResetContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 80vh;
  position: relative;

  .input-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }

  .signup-link{
    padding-top: 3%;
    font-size: small;
    margin-bottom: -3%;

    a{
      color: var(--Color1);
      font-weight: bold;
      text-decoration: underline;
    }
  }


  .query-message{
    color: white;
    text-align: center;
    font-size: small;
  }
  .login-logo{
        width: 150px;
        height: auto;
        margin: auto;
        padding-bottom: 10px;
    }
    a{
      height: 45px;
    }
    .company-logo{
    margin-bottom: 10px;
    /* width: 85px; */
    svg{
      width: 150px;
      color: white;
      fill: white;
    }
  }


  .new-to{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: medium;
    font-family: var(--Font1);
    color: rgb(34, 34, 32);

    span{
      background: #ccc;
      display: flex;
      width: 30%;
      height: 1px;
    }

    h5{
      font-size: small;
      margin-top: 10px;
      margin-bottom: 10px;
    }



    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
      font-size: small;

      span{
        width: 30px;

      }
      }
  }

  .token-expired-text{
    color: black;
    a{
      color: black;
    }
  }
  .company-logo{
    margin-bottom: 20px;
    height: 50px;
    width: 85px;
  }

  .token-expired-text{
    font-size: small;
  }

  .successfully-sent{
    text-align: center;
    line-height: 100px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 100px;
  }

  .submit-button{
    width: 100%;
    font-family: var(--Font2);
    font-weight: bold;
    height: 40px;
    background: var(--PrimaryColor);
    border-radius: 4px;
    color: white;
    text-transform: uppercase;
    border: 1px solid transparent;
    font-size: medium;
    cursor: pointer;
    

    a{
      text-decoration: none;
    }

    :active{
      background: var(--PrimaryColorDarker);
    }
  }

  .column-title{
    font-size: x-large;
    line-height: 30px;
    text-align: center;
    font-family: var(--Font1);
    font-weight: bold;
    color: rgb(34, 34, 32);
  }


.column-span{
  font-weight: bold;
  color: var(--Color1);
}

.column-description{
  font-size: small;
  font-family: var(--Font2);
  margin-top: 10px;
  margin-bottom: 30px;
  color: #4d4d4d;


}

  .wrapper{
    display: flex;
    align-items: center;
    width: 100%;

    min-width: 300px;
    max-width: 500px;
    padding: 2%;
    flex-direction: column;

    .wrapper-content{

      padding: 5%;
      width: inherit;
      height: inherit;
      background: white;
      border-radius: 4px;
      min-height: 451px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`



const BareLayout = ({ children }) => {

  return (
    <>
      <ThemeTopLayout theme={muiTheme}>
        <GlobalStyle theme={theme} />
        <AuthProvider>
          <ErrorsProvider>
            <Container>
              <Content>
                {children}
              </Content>
            </Container>
          </ErrorsProvider>
        </AuthProvider>
      </ThemeTopLayout>
    </>
  )
}

BareLayout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default BareLayout


