


import React from "react"
import SEO from "../components/Layout/SEO"
import BareLayout from "../components/Layout/BareLayout"
import Register from "../components/Register"

const RegisterPage = () => (
  <BareLayout>
    <SEO title="Join the world's best herb encyclopedia" />
    <Register />
  </BareLayout>
)

export default RegisterPage
